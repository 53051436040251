import styled from "styled-components";
import { Metadata } from "@joshuaavalon/component";

import { PageProps } from "./type";
import { useSiteMetadata } from "../hook";

const StyledHeader = styled.h1`
  font-family: ${props => props.theme.font.header};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  font-family: ${props => props.theme.font.body};
`;

export default function NotFoundPage(
  props: PageProps<Record<string, unknown>, Record<string, unknown>>
): JSX.Element {
  const { location } = props;
  const { pathname } = location;
  const siteMetadata = useSiteMetadata();
  return (
    <>
      <Metadata
        title="404: Not found"
        pathname={pathname}
        siteMetadata={siteMetadata}
      />
      <StyledWrapper>
        <StyledHeader>NOT FOUND</StyledHeader>
        <p>You just hit a route that doesn&#39;t exist.</p>
      </StyledWrapper>
    </>
  );
}
